import axios from "axios";

export function sendCustomEvent(type) {
  if (type && window?.location && window?.plausible) {
    window.plausible(type, {});
    if (type === "error") {
      return;
    }
    axios.get(`${window.location.origin}/api/c?type=${type}`).catch();
  }
}
