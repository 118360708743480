import "./src/styles/global.css";

import * as React from "react";
import { StoreProvider } from "./src/context/store-context";
import tpwsConfig from "./tpws.config.js";
import { sendCustomEvent } from "./src/components/plausible.js";

export const wrapRootElement = ({ element }) => {
  window.onerror = (message, source, lineno, colno, error) => {
    sendCustomEvent("error");
    console.error(error);
    return true;
  };
  window.addEventListener("unhandledrejection", () => {
    sendCustomEvent("error");
  });

  if (!tpwsConfig?.shopify) {
    return element;
  }
  return <StoreProvider>{element}</StoreProvider>;
};
